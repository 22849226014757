import { LoadingButton } from "@mui/lab";
import { TextField, Stack } from "@mui/material";
import { useEffect, useState } from "react";

interface TeamsMessageConfigProps {
  data: TeamsMessageConfig | undefined;
  onSaveChanges: (data: TeamsMessageConfig) => Promise<void>;
}

export interface TeamsMessageConfig {
    UseDirectoryIntegration: boolean
    TenantId: string;
    ClientId: string;
    ClientSecret: string;
}

function WebHookConfiguration(props: TeamsMessageConfigProps) {

    const [useDirectoryIntegration, setUseDirectoryIntegration] = useState(false);
    const [tenantId, setTenantId] = useState("");
    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (props.data) {
            setUseDirectoryIntegration(props.data.UseDirectoryIntegration);
            setTenantId(props.data.TenantId);
            setClientId(props.data.ClientId);
            setClientSecret(props.data.ClientSecret);
        }
    }, [props.data]);

    const handleSave = async () => {
        setIsSaving(true);

        await props.onSaveChanges({
            UseDirectoryIntegration: useDirectoryIntegration,
            TenantId: tenantId,
            ClientId: clientId,
            ClientSecret: clientSecret
        });

        setIsSaving(false);
    };

    return (
        <Stack>
            <Stack  marginTop={"20px"} direction={"row"} flexWrap={"wrap"} gap={3}>
                <TextField
                    sx={{ minWidth: "300px", flexGrow: 1 }}
                    id="tenant-id-input"
                    label="Tenant ID"
                    variant="outlined"
                    value={tenantId}
                    disabled={useDirectoryIntegration || isSaving}
                    onChange={(x) => setTenantId(x.target.value)}
                />
                <TextField
                    sx={{ minWidth: "300px", flexGrow: 1 }}
                    id="client-id-input"
                    label="Client ID"
                    variant="outlined"
                    value={clientId}
                    disabled={useDirectoryIntegration || isSaving}
                    onChange={(x) => setClientId(x.target.value)}
                />
                <TextField
                    sx={{ minWidth: "300px", flexGrow: 1 }}
                    id="client-secret-input"
                    label="Client Secret"
                    variant="outlined"
                    value={clientSecret}
                    disabled={useDirectoryIntegration || isSaving}
                    onChange={(x) => setClientSecret(x.target.value)}
                />
            </Stack>
            <Stack direction={"row"} gap={2} justifyContent={"end"} marginTop={"20px"}>
                <LoadingButton
                    id="save-changes-button"
                    variant="contained"
                    loading={isSaving}
                    onClick={handleSave}
                >
                    Save Changes
                </LoadingButton>
            </Stack>
        </Stack>
    );
}

export default WebHookConfiguration;