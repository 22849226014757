import { DisabledByDefaultRounded } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

type ThreeStateCheckboxProps = {
    hasMatch: boolean;
    hasNoMatch: boolean;
    onMatch: () => void;
    onNA: () => void;
    onNotMatch: () => void;
};

const ThreeStateCheckbox: React.FC<ThreeStateCheckboxProps> = ({ hasMatch, hasNoMatch, onMatch, onNA, onNotMatch }) => {

    const handleClick = () => {
        if (!hasMatch && !hasNoMatch)
            return onMatch();

        if (hasMatch)
            return onNotMatch();

        if (hasNoMatch)
            return onNA();
    };

    return <Checkbox checked={hasMatch} indeterminate={hasNoMatch} onClick={handleClick} indeterminateIcon={<DisabledByDefaultRounded/>} color={hasNoMatch ? "error" : "primary"} />;
};

export default ThreeStateCheckbox;
