import { TabContext, TabPanel } from "@mui/lab";
import { Box, Card, Stack, Switch, Tab, Tabs, Typography } from "@mui/material";
import Loading from "components/Loading";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import { useNavigate } from "react-router";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { SlackIcon } from "./_components/SlackIcon";
import TeamsConfiguration, { TeamsMessageConfig } from "./_components/TeamsConfig";
import { TeamsIcon } from "./_components/TeamsIcon";
import { DiscordIcon } from "./_components/DiscordIcon";

type notifiers = "discord" | "slack" | "teams";
 
function MessageIntegration() {
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [teamsData, setTeamsData] = useState<TeamsMessageConfig>();
    const [azureDirectoryData, setAzureDirectoryData] = useState<TeamsMessageConfig>();
    const [discordEnabled, setDiscordEnabled] = useState(false);
    const [slackEnabled, setSlackEnabled] = useState(false);
    const [teamsEnabled, setTeamsEnabled] = useState(false);
    const [isSavingTeams, setIsSavingTeams] = useState(false);
    const [currentTab, setCurrentTab] = useState<notifiers>("teams");
    const login = useLogin();
    const navigate = useNavigate();
    const notification = useNotification();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue as notifiers);
    };

    const onEnableSlack = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSlackEnabled(event.target.checked);
        await saveMessageSettings(event.target.checked, "slack");
    };

    const onEnableDiscord = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscordEnabled(event.target.checked);
        await saveMessageSettings(event.target.checked, "discord");
    };

    const onEnableTeams = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamsEnabled(event.target.checked);
        await saveMessageSettings(event.target.checked, "teams");
    };

    const onLoadTeamsFromDirectory = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (azureDirectoryData) {
            setTeamsData({
                UseDirectoryIntegration: event.target.checked,
                TenantId: azureDirectoryData.TenantId,
                ClientId: azureDirectoryData.ClientId,
                ClientSecret: azureDirectoryData.ClientSecret,
            });
        }
    };

    const req = login.GetAxios();

    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const respConfig = await req.get("/api/config");
            const settings = respConfig.data;

            if (settings.teamsSettings.messageIntegration) {

                const data: TeamsMessageConfig = {
                    UseDirectoryIntegration: settings.teamsSettings.messageIntegration.useDirectoryIntegration,
                    TenantId: settings.teamsSettings.messageIntegration.tenantId,
                    ClientId: settings.teamsSettings.messageIntegration.clientId,
                    ClientSecret: settings.teamsSettings.messageIntegration.clientSecret,
                };

                if (data.UseDirectoryIntegration) {
                    data.TenantId = settings.azureTenantId;
                    data.ClientId = settings.azureClientId;
                    data.ClientSecret = settings.azureClientSecret;

                }

                setTeamsData(data);
            }

            if (settings.azureTenantId && settings.azureClientId && settings.azureClientSecret) {
                setAzureDirectoryData({
                    UseDirectoryIntegration: false,
                    TenantId: settings.azureTenantId,
                    ClientId: settings.azureClientId,
                    ClientSecret: settings.azureClientSecret,
                });
            }


            if (settings.messageIntegrationSettings) {
                setSlackEnabled(settings.messageIntegrationSettings.isSlackEnabled);
                setDiscordEnabled(settings.messageIntegrationSettings.isDiscordEnabled);
                setTeamsEnabled(settings.messageIntegrationSettings.isTeamsEnabled);
            }

        } catch (err) {
            console.log(err);
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching configuration data",
                type: "error",
            });
        }
        setFinishedLoading(true);
    };

    const saveTeamsChanges = async (data: TeamsMessageConfig) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            let payload = {
                useDirectoryIntegration: data.UseDirectoryIntegration,
                tenantId: data.TenantId,
                clientId: data.ClientId,
                clientSecret: data.ClientSecret,
            };

            if (data.UseDirectoryIntegration) {
                payload.tenantId = "";
                payload.clientId = "";
                payload.clientSecret = "";
            }

            setIsSavingTeams(true);
            await req.post("/api/config/message/teams", payload);

            notification.Display({
                title: "Success",
                message: "Teams configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Teams configuration didn't worked as expected please review your keys and API permission.",
                type: "error",
            });
        }

        setIsSavingTeams(false);
    };

    const saveMessageSettings = async (v: boolean, n: notifiers) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {

            let data = {
                isSlackEnabled: discordEnabled,
                isDiscordEnabled: discordEnabled,
                isTeamsEnabled: teamsEnabled,
            };

            switch (n) {
            case "slack":
                data.isSlackEnabled = v;
                break;
            case "discord":
                data.isDiscordEnabled = v;
                break;
            case "teams":
                data.isTeamsEnabled = v;
                break;

            default:
                break;
            }

            await req.post("/api/config/message/settings", data);

            notification.Display({
                title: "Success",
                message: "Configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Loading finished={finishedLoading}>
            <Typography variant="h1">Message Integrations</Typography>
            <Card sx={{ marginTop: "10px", padding: "0px" }}>
                <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
                    <TabContext value={currentTab}>
                        <Tabs
                            orientation="vertical"
                            value={currentTab}
                            sx={{ borderRight: 1, borderColor: "divider" }}
                            onChange={handleTabChange}
                        >
                            <Tab
                                icon={<TeamsIcon />}
                                label="Teams"
                                value="teams"
                                id="teams-configuration"
                            ></Tab>
                            <Tab
                                icon={<SlackIcon />}
                                label="Slack"
                                value="slack"
                                id="slack-configuration"
                            ></Tab>

                            <Tab
                                icon={<DiscordIcon />}
                                label="Discord"
                                value="discord"
                                id="discord-configuration"
                            ></Tab>
                        </Tabs>
                        <TabPanel value="teams" sx={{ width: "100%", maxWidth: "1200px" }}>
                            <Typography variant="h2">Teams Configuration</Typography>
                            <Typography variant="body2" color="text.secondary">Please set up our application in your Azure Portal, and ensure that you create Application API permissions for the following resources: "Directory.Read.All", "User.Read.All", "ChannelMessage.Read.All", "ChannelMessage.UpdatePolicyViolation.All", "Chat.UpdatePolicyViolation.All", "Chat.ReadWrite.All", and "ChatMessage.Read.All".</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "flex",
                                direction: "row",
                                justifyContent: "space-between",
                            }}>
                                <Stack direction="row" alignItems={"center"}>
                                    <Switch checked={teamsEnabled} onChange={onEnableTeams} />
                                    <Typography>Enable</Typography>
                                </Stack>
                                <Stack direction="row" alignItems={"center"}>
                                    <Switch disabled={!azureDirectoryData || isSavingTeams} checked={teamsData?.UseDirectoryIntegration} onChange={onLoadTeamsFromDirectory} />
                                    <Typography>Load info from Directory Integration?</Typography>
                                </Stack>
                            </Box>
                            <TeamsConfiguration data={teamsData} onSaveChanges={saveTeamsChanges}></TeamsConfiguration>
                        </TabPanel>
                        <TabPanel value="slack" sx={{ width: "100%" }}>
                            <Typography variant="h2">Slack Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "none"
                            }}>
                                <Switch disabled={true} checked={slackEnabled} onChange={onEnableSlack} />
                                <Typography>Enable</Typography>
                            </Box>
                            <Typography variant="body1">coming soon</Typography>
                        </TabPanel>
                        <TabPanel value="discord" sx={{ width: "100%" }}>
                            <Typography variant="h2">Discord Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "none"
                            }}>
                                <Switch disabled={true} checked={discordEnabled} onChange={onEnableDiscord} />
                                <Typography>Enable</Typography>
                            </Box>
                            <Typography variant="body1">coming soon</Typography>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Card>
        </Loading>
    );
}

export default MessageIntegration;