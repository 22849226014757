import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import AlertLogIcon from "@mui/icons-material/PrivacyTipOutlined";
import AlertIcon from "@mui/icons-material/AlarmOnOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import DataObjectIcon from "@mui/icons-material/DataObject";
import PasswordIcon from "@mui/icons-material/Password";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import GroupsIcon from "@mui/icons-material/Groups";
import SendIcon from "@mui/icons-material/SendOutlined";
import { AuthContextData } from "providers/Login";
import { AdminPanelSettingsOutlined, CodeOutlined, FolderSharedOutlined, MessageOutlined, OpenInNew, SettingsApplications, SupervisedUserCircleOutlined  } from "@mui/icons-material";

export const MenuDefinition: Menu = {
    itens: [
        {
            type: "item",
            icon: <DashboardIcon />,
            text: "Dashboard",
            path: "/",
        },
        {
            type: "group",
            icon: <BarChartIcon />,
            text: "Activities",
            defaultPath: "/activities",
            itens: [
                {
                    type: "item",
                    icon: <AdminPanelSettingsOutlined />,
                    text: "Data Log",
                    path: "/activities",
                },
                {
                    type: "item",
                    icon: <AlertLogIcon />,
                    text: "Alert Log",
                    path: "/alertlogs",
                },
            ]
        },
        {
            type: "group",
            icon: <VerifiedUserOutlinedIcon />,
            text: "Policy",
            defaultPath: "/rules",
            itens: [
                {
                    type: "item",
                    icon: <AlertIcon />,
                    text: "Alerts",
                    path: "/alerts",
                },
                {
                    type: "item",
                    icon: <RuleIcon />,
                    text: "Rules",
                    path: "/rules",
                },
                {
                    type: "item",
                    icon: <AppsIcon />,
                    text: "Applications",
                    path: "/applications",
                },
                {
                    type: "item",
                    icon: <DataObjectIcon />,
                    text: "Data Type",
                    path: "/datatypes",
                },
                {
                    type: "item",
                    icon: <PasswordIcon />,
                    text: "Mask Formats",
                    path: "/maskformats",
                },
                {
                    type: "item",
                    icon: <SettingsEthernetIcon />,
                    text: "Obfuscations",
                    path: "/obfuscations",
                },
                {
                    type: "item",
                    icon: <GroupsIcon />,
                    text: "Groups",
                    path: "/groups"
                },
            ]
        },
        {

            type: "group",
            icon: <SettingsOutlinedIcon />,
            text: "Configuration",
            defaultPath: "/configuration",
            itens: [
                {
                    type: "item",
                    icon: <SettingsApplications />,
                    text: "General",
                    path: "/configuration",
                },
                {
                    type: "item",
                    icon: <AdminPanelSettingsOutlined />,
                    text: "ICAP",
                    path: "/icap",
                },
                {
                    type: "item",
                    icon: <SupervisedUserCircleOutlined />,
                    text: "SAML Sessions",
                    path: "/samlsessions",
                },
                {
                    type: "item",
                    icon: <FolderSharedOutlined />,
                    text: "Directory",
                    path: "/directory",
                },
                {
                    type: "item",
                    icon: <SendIcon />,
                    text: "Notifications",
                    path: "/alertnotification",
                },
                {
                    type: "item",
                    icon: <MessageOutlined />,
                    text: "Message Integrations",
                    path: "/message-integration",
                },
                {
                    type: "item",
                    icon: <CodeOutlined />,
                    text: "API Keys",
                    path: "/apikeys",
                },
               
                {
                    type: "item",
                    icon: <OpenInNew />,
                    text: "Test Page",
                    path: "/test",
                    newTab: true,
                },
            ]
        },
    ]
};


export interface Menu {
    itens: (MenuItemRoute | MenuItemGroup)[];
}

export interface MenuItemRoute {
    type: "item"
    icon: React.ReactChild
    text: string
    path: string
    newTab?: boolean
    badge?: (ctx: AuthContextData) => boolean
}

export interface MenuItemGroup {
    type: "group"
    icon: React.ReactChild
    text: string
    itens: MenuItemRoute[]
    defaultPath?: string
}