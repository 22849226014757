import {
    Alert,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import { useNotification } from "providers/Notification";
import { Cloud, Google, TripOrigin } from "@mui/icons-material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
type integration = "none" | "google" | "azure" | "okta" | "ldap";

type AuthModeType = "saml" | "proxy" | "none";

const displayNames: { [key in integration]: string } = {
    "none": "",
    "google": "Google Directory",
    "azure": "Azure Directory",
    "okta": "Okta Directory",
    "ldap": "LDAP"
};


function Integrations() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [hasGoogleCredential, setHasGoogleCredential] = useState(false);
    const [hasGoogleToken, setHasGoogleToken] = useState(false);
    const [googleConsentUrl, setGoogleConsentUrl] = useState("");
    const [isSaving, setIsSaving] = useState(false);


    const [azureTenantId, setAzureTenantId] = useState("");
    const [azureClientId, setAzureClientId] = useState("");
    const [azureClientSecret, setAzureClientSecret] = useState("");


    const [oktaApiDomain, setOktaApiDomain] = useState("");
    const [oktaApiToken, setOktaApiToken] = useState("");

    const [ldapEnabled, setLdapEnabled] = useState(false);
    const [ldapAddr, setLdapAddr] = useState("");
    const [ldapUsername, setLdapUsername] = useState("");
    const [ldapPassword, setLdapPassword] = useState("");
    const [ldapUsernameHeader, setLdapUsernameHeader] = useState("");

    const [currentTab, setCurrentTab] = useState<integration>("google");

    const [currentEnabledIntegration, setCurrentEnabledIntegration] = useState<integration>("none");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue as integration);
    };
    const [currentAuthenticationMode, setCurrentAuthenticationMode] = useState<AuthModeType>("none");

    const req = login.GetAxios();

    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const respConfig = await req.get("/api/config");
            const settings = respConfig.data;

            setHasGoogleCredential(settings.hasGoogleCredential);
            setHasGoogleToken(settings.hasGoogleToken);
            setGoogleConsentUrl(settings.googleConsentUrl);

            setAzureTenantId(settings.azureTenantId);
            setAzureClientId(settings.azureClientId);
            setAzureClientSecret(settings.azureClientSecret);

            setOktaApiDomain(settings.oktaApiDomain);
            setOktaApiToken(settings.oktaApiToken);

            if (settings.enableGoogleDirectoryIntegration) {
                setCurrentEnabledIntegration("google");
                setCurrentTab("google");
                setLdapEnabled(false);
            } else if (settings.enableAzureDirectoryIntegration) {
                setCurrentEnabledIntegration("azure");
                setCurrentTab("azure");
                setLdapEnabled(false);
            } else if (settings.enableOktaDirectoryIntegration) {
                setCurrentEnabledIntegration("okta");
                setCurrentTab("okta");
                setLdapEnabled(false);
            } else if (settings.ldapOptions.enable) {
                setCurrentEnabledIntegration("ldap");
                setCurrentTab("ldap");
                setLdapEnabled(true);
            } else {
                setCurrentEnabledIntegration("none");
                setLdapEnabled(false);
            }

            setCurrentAuthenticationMode(settings.authenticationMode || "none");

            setLdapUsernameHeader(settings.ldapOptions?.usernameIcapHeader || "");
            setLdapUsername(settings.ldapOptions?.username || "");
            setLdapAddr(settings.ldapOptions?.addr || "");

            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching configuration data",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const uploadGoogleCredentials = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        setIsSaving(true);

        const file = event.target && event.target.files && event.target.files[0] as Blob;
        if (file) {
            const data = new FormData();
            data.append("googleCredentials", file, "credentials.json");

            try {
                let res = await req.post("/api/config/google/integrate", data);
                setHasGoogleCredential(true);
                setHasGoogleToken(false);
                setGoogleConsentUrl(res.data.consentUrl);
            } catch (err) {
                notification.Display({
                    title: "Error",
                    message: "Credentials file invalid, check your if you uploaded the correct file.",
                    type: "error",
                });
            }
        }

        setIsSaving(false);
    };

    const saveAzureChanges = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        setIsSaving(true);

        try {
            await req.post("/api/config/azure/integrate", {
                azureTenantId,
                azureClientId,
                azureClientSecret
            });

            setAzureClientSecret("**********");

            notification.Display({
                title: "Success",
                message: "Azure integrated successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Azure Integration didn't worked as expected please review your app setup and permissions on azure portal.",
                type: "error",
            });
        }

        setIsSaving(false);
    };

    const saveOktaChanges = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        setIsSaving(true);

        try {
            await req.post("/api/config/okta/integrate", {
                oktaApiDomain,
                oktaApiToken,
            });

            setOktaApiToken("**********");

            notification.Display({
                title: "Success",
                message: "Okta integrated successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Okta Integration didn't worked as expected please review your app setup and permissions on okta portal.",
                type: "error",
            });
        }

        setIsSaving(false);
    };


    const updateConfig = async (f: (c: any) => Promise<void>) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            const respConfig = await req.get("/api/config");
            const settings = respConfig.data;

            await f(settings);

            await req.put("/api/config", settings);

            await loadData();

        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Error changing configuration",
                type: "error",
            });
            console.error(err);
        }
    };

    const saveLdapChanges = async () => {
        await updateConfig(async (config) => {
            config.ldapOptions = {
                ...(config.ldapOptions || {}),
                enable: ldapEnabled,
                addr: ldapAddr,
                username: ldapUsername,
                usernameIcapHeader: ldapUsernameHeader,
                password: ldapPassword || undefined,
            };
        });
    };

    const canSaveOkta = oktaApiDomain && oktaApiToken && oktaApiToken !== "**********"; 
    const canSaveAzure = azureTenantId && azureClientId && azureClientSecret && azureClientSecret !== "**********";

    return <>
        <Loading finished={finishedLoading}>
            <Typography variant="h1">Directory Integrations</Typography>

            {currentAuthenticationMode === "none" ? <Alert
                sx={{
                    marginTop: "10px",
                }}
                severity="warning"
            >
                To use directory integrations an authentication method must be enabled.
            </Alert> : ""}
            <Alert
                sx={{
                    visibility: (currentEnabledIntegration !== currentTab && currentEnabledIntegration !== "none") ? "visible" : "hidden",
                    marginTop: "10px",
                }}
                severity="error"
            >
                <Link href="#" onClick={() => { setCurrentTab(currentEnabledIntegration); }}>{displayNames[currentEnabledIntegration]}</Link> enabled. Disable integration before configuring {displayNames[currentTab]}.
            </Alert>

            <Card sx={{ marginTop: "0px", padding: "0px" }}>
                <Box
                    sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}
                >
                    <TabContext value={currentTab}>
                        <Tabs
                            orientation="vertical"
                            value={currentTab}
                            onChange={handleTabChange}
                            sx={{ borderRight: 1, borderColor: "divider" }}
                        >
                            <Tab icon={<Google />} label="Google" value="google" id="google-directory" />
                            <Tab icon={<Cloud />} label="Azure" value="azure" id="azure-directory" />
                            <Tab icon={<TripOrigin />} label="Okta" value="okta" id="okta-directory" />
                            <Tab icon={<FolderOpenIcon />} label="LDAP" value="ldap" id="ldap-directory" />
                        </Tabs>
                        <TabPanel value="google" sx={{ width: "100%" }}>
                            <Typography variant="h2">Google Directory</Typography>
                            <Typography variant="body2" color="text.secondary">Please download your credentials.json from the google directory admin console and follow the steps bellow to enable the integration.</Typography>
                            <EnableToggle
                                disabled={currentEnabledIntegration !== "google" && currentEnabledIntegration !== "none"}
                                enabled={currentEnabledIntegration === "google"}
                                onChange={(enabled) => {
                                    updateConfig(async (settings) => {
                                        settings.enableGoogleDirectoryIntegration = enabled;
                                        settings.enableOktaDirectoryIntegration = false;
                                        settings.enableAzureDirectoryIntegration = false;
                                    });
                                }}
                            />
                            <Grid container sx={{
                                marginBottom: "10px",
                                marginTop: "20px"
                            }}>
                                <Grid item sm={3}>
                                    <Typography variant="h4">Step 1</Typography>
                                    <LoadingButton variant="contained" component="label" loading={isSaving}>
                                        {hasGoogleCredential ? "Replace credentials.json" : "Upload credentials.json"}
                                        <input hidden accept=".json" type="file" onChange={uploadGoogleCredentials} />
                                    </LoadingButton>
                                </Grid>
                                <Grid item sm={1} />
                                <Grid item sm={3}>
                                    <Typography variant="h4">Step 2</Typography>
                                    {!hasGoogleToken && googleConsentUrl ?
                                        <Link href={googleConsentUrl}>Click here to consent</Link>
                                        : <Typography color="text.secondary">{hasGoogleToken ? "Consent Granted" : "Consent"}</Typography>}
                                </Grid>
                                <Grid item sm={1} />
                                <Grid item sm={3}>
                                    <Typography variant="h4">Status</Typography>
                                    {hasGoogleCredential ? (hasGoogleToken ? "Successfull" : "Consent Pending") : "Credentials Pending"}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="azure" sx={{ width: "100%" }}>
                            <Typography variant="h2">Azure Directory</Typography>
                            <Typography variant="body2" color="text.secondary">Please setup our application into your azure portal and make sure create API Permissions (as an Application) for "Directory.Read.All" and "User.Read.All" resources.</Typography>
                            <EnableToggle
                                disabled={currentEnabledIntegration !== "azure" && currentEnabledIntegration !== "none"}
                                enabled={currentEnabledIntegration === "azure"}
                                onChange={(enabled) => {
                                    updateConfig(async (settings) => {
                                        settings.enableGoogleDirectoryIntegration = false;
                                        settings.enableOktaDirectoryIntegration = false;
                                        settings.enableAzureDirectoryIntegration = enabled;
                                    });
                                }}
                            />
                            <Grid container sx={{
                                marginBottom: "10px",
                                marginTop: "20px"
                            }}>
                                <Grid item sm={3}>
                                    <TextField
                                        id="azure-tenant-id-input"
                                        label="Directory (tenant) ID"
                                        fullWidth
                                        variant="outlined"
                                        value={azureTenantId}
                                        onChange={(x) => setAzureTenantId(x.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={1} />
                                <Grid item sm={3}>
                                    <TextField
                                        id="azure-client-id-input"
                                        label="Application (client) ID"
                                        fullWidth
                                        variant="outlined"
                                        value={azureClientId}
                                        onChange={(x) => setAzureClientId(x.target.value)}
                                    />
                                    
                                    
                                </Grid>
                                <Grid item sm={1} />
                                <Grid item sm={3}>
                                    <TextField
                                        id="azure-client-secret-input"
                                        label="Client Secret Value"
                                        fullWidth
                                        variant="outlined"
                                        value={azureClientSecret}
                                        onChange={(x) => setAzureClientSecret(x.target.value)}
                                    />
                                    <Box display="flex" sx={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <LoadingButton
                                            id="save-changes-button"
                                            variant="contained"
                                            sx={{ marginLeft: "auto" }}
                                            disabled={!canSaveAzure}
                                            onClick={saveAzureChanges}
                                            loading={isSaving}>Save Changes</LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="okta" sx={{ width: "100%" }}>
                            <Typography variant="h2">Okta Directory</Typography>
                            <Typography variant="body2" color="text.secondary">Please generate a API token into your okta admin dashboard to setup our Okta Directory integration.</Typography>
                            <EnableToggle
                                disabled={currentEnabledIntegration !== "okta" && currentEnabledIntegration !== "none"}
                                enabled={currentEnabledIntegration === "okta"}
                                onChange={(enabled) => {
                                    updateConfig(async (settings) => {
                                        settings.enableGoogleDirectoryIntegration = false;
                                        settings.enableOktaDirectoryIntegration = enabled;
                                        settings.enableAzureDirectoryIntegration = false;
                                    });
                                }}
                            />
                            <Grid container sx={{
                                marginBottom: "10px",
                                marginTop: "20px"
                            }}>
                                <Grid item sm={3}>
                                    <TextField
                                        id="okta-api-domain-input"
                                        label="Okta API Domain"
                                        fullWidth
                                        variant="outlined"
                                        value={oktaApiDomain}
                                        onChange={(x) => setOktaApiDomain(x.target.value)}
                                    />
                                </Grid>
                                <Grid item sm={1} />
                                <Grid item sm={3}>
                                    <TextField
                                        id="okta-api-token-input"
                                        label="API Token"
                                        fullWidth
                                        variant="outlined"
                                        value={oktaApiToken}
                                        onChange={(x) => setOktaApiToken(x.target.value)}
                                    />
                                    <Box display="flex" sx={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <LoadingButton
                                            id="save-okta-changes-button"
                                            variant="contained"
                                            sx={{ marginLeft: "auto" }}
                                            disabled={!canSaveOkta}
                                            onClick={saveOktaChanges}
                                            loading={isSaving}>Save Changes</LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="ldap" sx={{ width: "100%" }}>
                            <Box>
                                <Typography variant="h2">LDAP</Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            id="enable_ldap_checkbox"
                                            checked={ldapEnabled}
                                            onChange={(e) => setLdapEnabled(e.target.checked)}
                                            disabled={currentEnabledIntegration !== "ldap" && currentEnabledIntegration !== "none"}
                                        />
                                    }
                                    label="Enable LDAP"
                                />
                                <Grid container sx={{
                                    marginBottom: "10px",
                                    maxWidth: "350px",
                                }}>
                                    <Grid item sm={12}>
                                        <TextField
                                            fullWidth
                                            sx={{ marginTop: "15px" }}
                                            id="ldap_username_header_input"
                                            label="ICAP Username Header"
                                            variant="outlined"
                                            value={ldapUsernameHeader}
                                            disabled={currentEnabledIntegration !== "ldap" && currentEnabledIntegration !== "none"}
                                            onChange={(x) => setLdapUsernameHeader(x.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextField
                                            fullWidth
                                            sx={{ marginTop: "15px" }}
                                            id="ldap_host_input"
                                            label="Host"
                                            variant="outlined"
                                            disabled={currentEnabledIntegration !== "ldap" && currentEnabledIntegration !== "none"}
                                            value={ldapAddr}
                                            onChange={(x) => setLdapAddr(x.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextField
                                            fullWidth
                                            sx={{ marginTop: "15px" }}
                                            id="ldap_username_input"
                                            label="Username"
                                            variant="outlined"
                                            disabled={currentEnabledIntegration !== "ldap" && currentEnabledIntegration !== "none"}
                                            value={ldapUsername}
                                            onChange={(x) => setLdapUsername(x.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextField
                                            fullWidth
                                            sx={{ marginTop: "15px" }}
                                            id="ldap_password_input"
                                            label="Password"
                                            variant="outlined"
                                            type="password"
                                            disabled={currentEnabledIntegration !== "ldap" && currentEnabledIntegration !== "none"}
                                            value={ldapPassword}
                                            onChange={(x) => setLdapPassword(x.target.value)}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Box display="flex" >
                                            <LoadingButton
                                                id="save-changes-button"
                                                variant="contained"
                                                sx={{ marginLeft: "auto", marginTop: "15px" }}
                                                onClick={saveLdapChanges}
                                                loading={isSaving}>
                                                Save Changes
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Card>
        </Loading>
    </>;
}

interface EnableToggleProps {
    enabled: boolean
    disabled: boolean
    onChange: (v: boolean) => void
}

function EnableToggle(props: EnableToggleProps) {
    return <Box sx={{
        marginTop: "10px",
        alignItems: "center",
        display: "flex"
    }}>
        <Switch disabled={props.disabled} checked={props.enabled} onChange={() => props.onChange && props.onChange(!props.enabled)} />
        <Typography>Enable</Typography>
    </Box>;
}

export default Integrations;