import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { useNavigate } from "react-router";
import { useNotification } from "providers/Notification";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddNewAlert } from "./AddAlertPolicy";
import DataTable, { GetSortString, RestoreColumnsState, SaveColumnsState, useTableColumnState } from "components/Table";
import { ChipList } from "components/ChipList";
import { Circle, ToggleOffOutlined, ToggleOnOutlined } from "@mui/icons-material";

interface AlertListState {
    itens: AlertListStateItem[]
}

interface AlertListStateItem {
    id: string
    name: string
    description: string
    enable: boolean
    notificationTypes: number[]
    emailRecipients: string[]
    slackChannels: string[]
    lastTimeTriggered: number
    nextTimeTrigger: number
    createdat: Date
    updatedat: Date
}

type columnsType = "name" | "description" | "lastTimeTriggered" | "nextTimeTrigger" | "emailRecipients" | "enable" | "createdat" | "updatedat";

export default function Alerts() {
    const notification = useNotification();
    const login = useLogin();
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [addAlertDrawer, setAddAlertDrawer] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteDialogText, setDeleteDialogText] = useState("");
    const [deleteDialogAlertId, setDeleteDialogAlertId] = useState("");
    const [editingAlertId, setEditingAlertId] = useState("");

    const [columnsState, setColumnsState] = useTableColumnState<columnsType>([
        { columnId: "name", width: "15%", direction: "desc", sortOrder: 1 },
        { columnId: "description", width: "15%" },
        { columnId: "lastTimeTriggered", width: "10%" },
        { columnId: "nextTimeTrigger", width: "10%" },
        { columnId: "emailRecipients", width: "20%" },
        { columnId: "enable", width: "10%" },
        { columnId: "createdat", width: "10%", direction: "desc", sortOrder: 2 },
        { columnId: "updatedat", width: "10%" },
    ]);

    const columnsDataId = "dataGrid_AlertTable_columns_v2";

    const [listState, setListState] = useState<AlertListState>({ itens: [] });

    const req = login.GetAxios();

    const loadData = async (cState: typeof columnsState) => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            const sortBy = GetSortString(cState);

            const resp = await req.get("/api/alerts", {
                params: {
                    sortBy,
                }
            });

            setListState({
                itens: (resp.data.items || []).map((x: any) => {
                    const item: AlertListStateItem = {
                        id: x.id,
                        name: x.name,
                        enable: x.enable,
                        description: x.description,
                        notificationTypes: x.notificationTypes || [],
                        emailRecipients: x.emailRecipients || [],
                        slackChannels: x.slackChannels || [],
                        lastTimeTriggered: x.lastTimeTriggered,
                        nextTimeTrigger: x.nextTimeTrigger,
                        createdat: new Date(x.createdAt * 1000),
                        updatedat: new Date(x.updatedAt * 1000),
                    };
                    return item;
                })
            });

            setColumnsState(cState);
            setFinishedLoading(true);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Could not fetch data",
                type: "error",
            });
        }
    };

    const setAlertEnabled = async (id: string, state: boolean) => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.put(`/api/alerts/${id}/enable`, {
                enable: state,
            });
            loadData(columnsState);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Alert status updated successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not update rule status",
                type: "error",
            });
        }
    };


    useEffect(() => {
        const cstate = RestoreColumnsState(columnsDataId, columnsState);
        loadData(cstate);
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        SaveColumnsState(columnsDataId, columnsState);
    }, [columnsState]);


    const deleteRowDialog = (alertName: string, alertId: string) => {
        setDeleteDialog(true);
        setDeleteDialogText(`Are you sure you want to delete the alert "${alertName}"?`);
        setDeleteDialogAlertId(alertId);
    };

    const deleteAlert = async () => {
        if (!req) {
            return; //not logged in, nothing to do
        }

        try {
            await req.delete(`/api/alerts/${deleteDialogAlertId}`);
            loadData(columnsState);
            notification.Display({
                type: "success",
                title: "Success",
                message: "Alert deleted successfully"
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Could not delete alert",
                type: "error",
            });
        }
        setDeleteDialog(false);
    };

    const addAlert = () => {
        setEditingAlertId("");
        setAddAlertDrawer(true);
    };

    const editAlert = (appId: string) => {
        setEditingAlertId(appId);
        setAddAlertDrawer(true);
    };

    return <>
        <Box display="flex" flexDirection="column">
            <Box display="flex">
                <Typography variant="h1">Alerts</Typography>
                <Button
                    id="new-alert-button"
                    sx={{ marginLeft: "auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={addAlert}
                >
                    Add New Alert
                </Button>
            </Box>

            <DataTable
                data={listState.itens}
                finishedLoading={finishedLoading}
                onSortChanged={(updatedSort) => {
                    loadData(updatedSort);
                }}
                onColumnOrderChanged={(x) => {
                    setColumnsState(x);
                }}
                columnsDataId={columnsDataId}
                columnsState={columnsState}
                columns={{
                    name: {
                        displayName: "Name",
                        sortable: true,
                        render: (x) => x.name,
                    },
                    description: {
                        displayName: "Description",
                        sortable: true,
                        render: (x) => x.description,
                    },
                    lastTimeTriggered: {
                        displayName: "Last Time Triggered",
                        sortable: true,
                        render: (x) => {
                            if (x.lastTimeTriggered) {
                                let dateLastTimeTriggered = new Date(x.lastTimeTriggered * 1000);
                                return `${dateLastTimeTriggered.toLocaleDateString()} ${dateLastTimeTriggered.toLocaleTimeString()}`;
                            }

                            return "Never";
                        }
                    },
                    nextTimeTrigger: {
                        displayName: "Next Check",
                        sortable: true,
                        render: (x) => {
                            if (x.nextTimeTrigger && x.enable) {
                                let dateNextTimeTrigger = new Date(x.nextTimeTrigger * 1000);
                                return `${dateNextTimeTrigger.toLocaleDateString()} ${dateNextTimeTrigger.toLocaleTimeString()}`;
                            }

                            return "None";
                        }
                    },
                    emailRecipients: {
                        displayName: "Notifications",
                        render: (x) => {
                            return <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "start",
                                flexDirection: "row",
                                gap: "2px"
                            }}>
                                <ChipList items={x.emailRecipients} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="primary" />} />
                                <ChipList items={x.slackChannels} renderItem={(x) => <Chip label={x} title={x} variant="outlined" size="small" color="success" />} />
                                {x.notificationTypes && x.notificationTypes.includes(3) && <Chip label={"Webhook"} title={"Webhook"} variant="outlined" size="small" color="warning" /> }
                                {x.notificationTypes && x.notificationTypes.includes(4) && <Chip label={"Teams"} title={"Teams"} variant="outlined" size="small" color="secondary" /> }
                            </Box>;

                        },
                    },
                    enable: {
                        displayName: "Status",
                        render: (x) => {
                            return x.enable
                                ? <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <Circle sx={{transform: "scale(0.6)"}} fontSize="small" color="success" /><span>Active</span>
                                </Box>
                                : <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    <Circle sx={{transform: "scale(0.6)"}} fontSize="small" color="error" /><span>Inactive</span>
                                </Box>;
                        }
                    },
                    createdat: {
                        displayName: "Created At",
                        sortable: true,
                        render: (x) => {
                            return `${x.createdat.toLocaleDateString()} ${x.createdat.toLocaleTimeString()}`;
                        }
                    },
                    updatedat: {
                        displayName: "Updated At",
                        sortable: true,
                        render: (x) => {
                            return `${x.updatedat.toLocaleDateString()} ${x.updatedat.toLocaleTimeString()}`;
                        }
                    },
                }}
                rowOptionsMenu={[
                    {
                        icon: <EditIcon className="edit-button" fontSize="small" />,
                        text: "Edit",
                        onClick: (x) => {
                            editAlert(x.id);
                        }
                    },
                    {
                        icon: (x) => {
                            if(x.enable) {
                                return <ToggleOffOutlined/>;
                            }
                            return <ToggleOnOutlined/>;
                        },
                        text: (x) => {
                            if(x.enable) {
                                return "Turn Off";
                            } 
                            return "Turn On";
                        },
                        onClick: (x) => {
                            setAlertEnabled(x.id, !x.enable);
                        }
                    },
                    {
                        icon: <DeleteIcon className="delete-button" fontSize="small" />,
                        text: "Delete",
                        onClick: (x) => {
                            deleteRowDialog(x.name, x.id);
                        }
                    },
                ]}
            />
        </Box>

        <Dialog open={deleteDialog}>
            <DialogTitle>Delete Alert</DialogTitle>
            <DialogContent>
                <DialogContentText>{deleteDialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id="cancel-delete-button" variant="outlined" onClick={() => setDeleteDialog(false)}>
                    Cancel
                </Button>
                <Button id="confirm-delete-button" color="primary" autoFocus variant="contained" onClick={deleteAlert}>
                    Remove It
                </Button>
            </DialogActions>
        </Dialog>

        <Drawer
            anchor="right"
            open={addAlertDrawer}
        >
            <Box display="flex" flexDirection={"column"} sx={{ width: "580px", padding: "20px", flex: 1, height: "100%" }}>
                <AddNewAlert alertId={editingAlertId} onExit={() => setAddAlertDrawer(false)} onSave={() => { loadData(columnsState); setAddAlertDrawer(false); }} />
            </Box>
        </Drawer>
    </>;
}


