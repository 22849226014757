import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Card, Switch, Tab, Tabs, Typography } from "@mui/material";
import Loading from "components/Loading";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLogin } from "providers/Login";
import { useNotification } from "providers/Notification";
import { useNavigate } from "react-router";
import { ApiErrorCode } from "helpers/ApiErrorCode";
import { SlackIcon } from "./_components/SlackIcon";
import SMTPConfiguration, { SMTPConfigForm } from "./_components/SMTPConfig";
import { PagerDutyIcon } from "./_components/PagerDutyIcon";
import SlackConfiguration, { SlackConfigForm } from "./_components/SlackConfig";
import WebHookConfiguration, { WebhookConfigForm } from "./_components/WebhookConfig";
import { TeamsIcon } from "./_components/TeamsIcon";

type notifiers = "smtp" | "slack" | "pagerduty" | "webhook" | "teams";
 
function AlertNotification() {
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [smtpData, setSMTPData] = useState<SMTPConfigForm>();
    const [slackData, setSlackData] = useState<SlackConfigForm>();
    const [webhookData, setWebhookData] = useState<WebhookConfigForm>();
    const [teamsData, setTeamsData] = useState<WebhookConfigForm>();
    const [smtpEnabled, setSMTPEnabled] = useState(false);
    const [webhookEnabled, setWebhookEnabled] = useState(false);
    const [slackEnabled, setSlackEnabled] = useState(false);
    const [teamsEnabled, setTeamsEnabled] = useState(false);
    const [currentTab, setCurrentTab] = useState<notifiers>("smtp");
    const login = useLogin();
    const navigate = useNavigate();
    const notification = useNotification();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue as notifiers);
    };

    const onEnableSMTP = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSMTPEnabled(event.target.checked);
        await saveNotifers(event.target.checked, "smtp");
    };

    const onEnableSlack = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSlackEnabled(event.target.checked);
        await saveNotifers(event.target.checked, "slack");
    };

    const onEnableWebhook = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setWebhookEnabled(event.target.checked);
        await saveNotifers(event.target.checked, "webhook");
    };

    const onEnableTeams = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setTeamsEnabled(event.target.checked);
        await saveNotifers(event.target.checked, "teams");
    };


    const req = login.GetAxios();
    
    const loadData = async () => {
        setFinishedLoading(false);
        if (!req) {
            return; //not logged in, nothing to do
        }
        try {
            const respConfig = await req.get("/api/config");
            const settings = respConfig.data;
            if (settings.smtpNotificationSettings) {

                const data: SMTPConfigForm = {
                    SmartHost: settings.smtpNotificationSettings.smarthost,
                    From: settings.smtpNotificationSettings.from,
                    To: settings.smtpNotificationSettings.to,
                    Username: settings.smtpNotificationSettings.username,
                    Password: settings.smtpNotificationSettings.password,
                    Identity: settings.smtpNotificationSettings.identity,
                    RequireTLS: settings.smtpNotificationSettings.requireTls,
                };

                setSMTPData(data);
            }

            if (settings.slackNotificationSettings) {

                const data: SlackConfigForm = {
                    WebhookURL: settings.slackNotificationSettings.webhookURL,
                    Channel: settings.slackNotificationSettings.channel,
                    Username: settings.slackNotificationSettings.username,
                };

                setSlackData(data);
            }

            if (settings.webhookNotificationSettings) {

                const data: WebhookConfigForm = {
                    URL: settings.webhookNotificationSettings.url,
                };

                setWebhookData(data);
            }

            if (settings.teamsSettings.alertNotification) {

                const data: WebhookConfigForm = {
                    URL: settings.teamsSettings.alertNotification.url,
                };

                setTeamsData(data);
            }

            if (settings.notifiersSettings) {                
                setSlackEnabled(settings.notifiersSettings.isSlackEnabled);
                setSMTPEnabled(settings.notifiersSettings.isSMTPEnabled);
                setWebhookEnabled(settings.notifiersSettings.isWebhookEnabled);
                setTeamsEnabled(settings.notifiersSettings.isTeamsEnabled);
            }

        } catch (err) {
            console.log(err);
            if (axios.isAxiosError(err) && err.response) {
                const body = err.response.data;

                if (body.code === ApiErrorCode.ApiErrorUnauthorized) {
                    console.info("credentials invalid; redirecting to login page");
                    login.Logout();
                    navigate("/login");
                }
            }
            notification.Display({
                title: "Error",
                message: "Error fetching configuration data",
                type: "error",
            });
        }
        setFinishedLoading(true);
    };

    const saveSmtpChanges = async (data: SMTPConfigForm) => {
        if (!req) {
            return; //not logged in, nothing to do
        }       

        try {            
            await req.post("/api/config/notification/smtp", {
                smarthost: data.SmartHost,
                from: data.From,
                to: data.To,
                username: data.Username,
                password: data.Password,
                identity: data.Identity,
                requireTLS: data.RequireTLS
            });

            notification.Display({
                title: "Success",
                message: "SMTP configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "SMTP configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    const saveSlackChanges = async (data: SlackConfigForm) => {
        if (!req) {
            return; //not logged in, nothing to do
        }       

        try {            
            await req.post("/api/config/notification/slack", {
                webhookURL: data.WebhookURL,
                channel: data.Channel,
                username: data.Username,
            });

            notification.Display({
                title: "Success",
                message: "Slack configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Slack configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    const saveWebhookChanges = async (data: WebhookConfigForm) => {
        if (!req) {
            return; //not logged in, nothing to do
        }       

        try {            
            await req.post("/api/config/notification/webhook", {
                url: data.URL,
            });

            notification.Display({
                title: "Success",
                message: "Webhook configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Webhook configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    const saveTeamsChanges = async (data: WebhookConfigForm) => {
        if (!req) {
            return; //not logged in, nothing to do
        }       

        try {            
            await req.post("/api/config/notification/teams", {
                url: data.URL,
            });

            notification.Display({
                title: "Success",
                message: "Teams configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Teams configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    const saveNotifers = async (v: boolean, n: notifiers) => {
        if (!req) {
            return; //not logged in, nothing to do
        }    

        try {
            switch (n) {
            case "smtp":
                await req.post("/api/config/notification/notifiers", {
                    isSMTPEnabled: v,
                    isSlackEnabled: slackEnabled,
                    isWebhookEnabled: webhookEnabled,
                    isTeamsEnabled: teamsEnabled,
                });
                break;
            case "slack":
                await req.post("/api/config/notification/notifiers", {
                    isSMTPEnabled: smtpEnabled,
                    isSlackEnabled: v,
                    isWebhookEnabled: webhookEnabled,
                    isTeamsEnabled: teamsEnabled,
                });
                break;  

            case "webhook":
                await req.post("/api/config/notification/notifiers", {
                    isSMTPEnabled: smtpEnabled,
                    isSlackEnabled: slackEnabled,
                    isWebhookEnabled: v,
                    isTeamsEnabled: teamsEnabled,
                });
                break;  
            case "teams":
                await req.post("/api/config/notification/notifiers", {
                    isSMTPEnabled: smtpEnabled,
                    isSlackEnabled: slackEnabled,
                    isTeamsEnabled: v,
                    isWebhookEnabled: webhookEnabled,
                });
                break; 

            default:
                break;
            }          

            notification.Display({
                title: "Success",
                message: "Configuration saved successfully.",
                type: "success",
            });
        } catch (err) {
            notification.Display({
                title: "Error",
                message: "Configuration didn't worked as expected please review your settings.",
                type: "error",
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Loading finished={finishedLoading}>
            <Typography variant="h1">Notifications</Typography>
            <Card sx={{ marginTop: "10px", padding: "0px" }}>
                <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
                    <TabContext value={currentTab}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={currentTab}
                            sx={{ borderRight: 1, borderColor: "divider" }}
                            onChange={handleTabChange}
                        >
                            <Tab
                                icon={<MailOutlineOutlinedIcon />}
                                label="SMTP"
                                value="smtp"
                                id="smtp-configuration"
                            ></Tab>
                            <Tab
                                icon={<SlackIcon />}
                                label="Slack"
                                value="slack"
                                id="slack-configuration"
                            ></Tab>                            
                            <Tab
                                icon={<TeamsIcon />}
                                label="Teams"
                                value="teams"
                                id="teams-configuration"
                            ></Tab>
                            <Tab
                                icon={<WebhookOutlinedIcon />}
                                label="Webhook"
                                value="webhook"
                                id="webhook-configuration"
                            ></Tab>
                            <Tab
                                icon={<PagerDutyIcon />}
                                label="PagerDuty"
                                value="pagerduty"
                                id="pagerduty-configuration"
                            ></Tab>
                        </Tabs>
                        <TabPanel value="smtp" sx={{ width: "100%" }}>
                            <Typography variant="h2">SMTP Server Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "flex"
                            }}>
                                <Switch checked={smtpEnabled} onChange={onEnableSMTP} />
                                <Typography>Enable</Typography>
                            </Box>
                            
                            <SMTPConfiguration data={smtpData} onSaveChanges={saveSmtpChanges}></SMTPConfiguration>
                        </TabPanel>
                        <TabPanel value="slack" sx={{ width: "100%" }}>
                            <Typography variant="h2">Slack Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "flex"
                            }}>
                                <Switch checked={slackEnabled} onChange={onEnableSlack} />
                                <Typography>Enable</Typography>
                            </Box>
                            <SlackConfiguration data={slackData} onSaveChanges={saveSlackChanges}></SlackConfiguration>
                        </TabPanel>                        
                        <TabPanel value="teams" sx={{ width: "100%" }}>
                            <Typography variant="h2">Teams Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "flex"
                            }}>
                                <Switch checked={teamsEnabled} onChange={onEnableTeams} />
                                <Typography>Enable</Typography>
                            </Box>
                            <WebHookConfiguration notifier="teams" data={teamsData} onSaveChanges={saveTeamsChanges}></WebHookConfiguration>
                        </TabPanel>
                        <TabPanel value="webhook" sx={{ width: "100%" }}>
                            <Typography variant="h2">Webhook Configuration</Typography>
                            <Box sx={{
                                marginTop: "10px",
                                alignItems: "center",
                                display: "flex"
                            }}>
                                <Switch checked={webhookEnabled} onChange={onEnableWebhook} />
                                <Typography>Enable</Typography>
                            </Box>
                            <WebHookConfiguration notifier="webhook" data={webhookData} onSaveChanges={saveWebhookChanges}></WebHookConfiguration>
                        </TabPanel>
                        <TabPanel value="pagerduty" sx={{ width: "100%" }}>
                            <Typography variant="h2">PagerDuty Configuration</Typography>
                            <Typography variant="body1">coming soon</Typography>
                        </TabPanel>
                    </TabContext>
                </Box>    
            </Card>
        </Loading>
    );
}

export default AlertNotification;